<mat-card>
  <mat-card-title>
    <img src="../../../../../assets/images/logo.png" alt="Netex Logo">
  </mat-card-title>
  <mat-card-content>
    <form [formGroup]="form">
      <p>
        <mat-form-field appearance="outline">
          <mat-label>Username or Email</mat-label>
          <input type="text" matInput formControlName="username" (keydown.enter)="login()">
        </mat-form-field>
      </p>

      <p>
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input type="password" matInput formControlName="password" (keydown.enter)="login()">
        </mat-form-field>
      </p>

      <p class="login-buttons">
        <button type="button" mat-raised-button color="accent" (click)="login()">Login <mat-icon class="right">send</mat-icon></button>
      </p>

      <p *ngIf="errorMessage" class="login-error">
        {{ errorMessage }}
      </p>
    </form>
  </mat-card-content>
</mat-card>
