import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss']
})
export class NavItemComponent  {
  @Input() icon = '';
  @Input() hint = '';
  @Input() link: string | any[] = '/';
  @Input() linkActive: string | any[] = '';
  @Output() navigate = new EventEmitter();
}
