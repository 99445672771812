import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

export abstract class BaseOnDestroy implements OnDestroy {
  public isDestroyed$ = new Subject<boolean>();

  constructor() {
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
