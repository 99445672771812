import { Routes } from '@angular/router';
import { NotFoundPageComponent } from './core/components/not-found-page/not-found-page.component';
import { AuthGuard } from './auth/services/auth-guard.service';
const ɵ0 = () => import("./home/home.module.ngfactory").then(mod => mod.HomeModuleNgFactory), ɵ1 = () => import("./admin/admin.module.ngfactory").then(mod => mod.AdminModuleNgFactory);
export const routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    {
        path: 'home',
        loadChildren: ɵ0,
        canActivate: [AuthGuard]
    },
    {
        path: 'admin',
        loadChildren: ɵ1,
        canActivate: [AuthGuard]
    },
    { path: '**', component: NotFoundPageComponent }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1 };
