import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginFormComponent } from './components/login/login-form/login-form.component';
import { MaterialModule } from '../material';
import { LoginPageComponent } from './components/login/login-page/login-page.component';
import { LogoutConfirmationDialogComponent } from './components/logout/logout-confirmation-dialog/logout-confirmation-dialog.component';
import { StoreModule } from '@ngrx/store';
import { reducers } from './reducers';
import { AuthEffects } from './effects/auth.effects';
import { EffectsModule } from '@ngrx/effects';
import { LoginDialogComponent } from './components/login/login-dialog/login-dialog.component';

export const COMPONENTS = [
  LoginPageComponent,
  LoginFormComponent,
  LogoutConfirmationDialogComponent,
  LoginDialogComponent
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    AuthRoutingModule,
    StoreModule.forFeature('auth', reducers),
    EffectsModule.forFeature([AuthEffects]),
  ],
  declarations: COMPONENTS,
  entryComponents: [
    LogoutConfirmationDialogComponent,
    LoginDialogComponent
  ]
})
export class AuthModule { }
