import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceUnderscoresWithSpace'
})
export class ReplaceUnderscoresWithSpacePipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    return value ? value.replace(/_/g, ' ') : '';
  }

}
