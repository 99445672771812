import { AuthActions, AuthApiActions, LoginPageActions } from '../actions';
import { User } from '../models/user';

export interface State {
  user: User | null;
  error: string | null;
  pending: boolean;
}

export const initialState: State = {
  user: null,
  error: null,
  pending: false
};

export function reducer(
  state = initialState,
  action: AuthApiActions.AuthApiActionsUnion | AuthActions.AuthActionsUnion | LoginPageActions.LoginPageActionsUnion
): State {
  switch (action.type) {
    case AuthApiActions.setLoggedInUser.type:
    case AuthApiActions.loginSuccess.type: {
      return {
        ...state,
        user: action.user,
        error: null,
        pending: false
      };
    }

    case AuthActions.logout.type: {
      return initialState;
    }
    case LoginPageActions.login.type: {
      return {
        ...state,
        error: null,
        pending: true
      };
    }

    case AuthApiActions.loginFailure.type: {
      return {
        ...state,
        error: action.error,
        pending: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getUser = (state: State) => state.user;
export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
