import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '../material';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { NavItemComponent } from './components/nav-item/nav-item.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UnsavedChangesDialogComponent } from './components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { ReplaceUnderscoresWithSpacePipe } from './pipes/replace-underscores-with-space.pipe';
import { PrintDialogComponent } from './components/print-dialog/print-dialog.component';

export const COMPONENTS = [
  NotFoundPageComponent,
  NavItemComponent,
  ToolbarComponent,
  UnsavedChangesDialogComponent,
  ReplaceUnderscoresWithSpacePipe,
  PrintDialogComponent
];


@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  exports: [
    MaterialModule,
    COMPONENTS
  ],
  entryComponents: [
    UnsavedChangesDialogComponent,
    PrintDialogComponent
  ]
})
export class CoreModule {
}
