import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Credentials, User } from '../models/user';
import { ApiService } from '../../core/services/api.service';
import { StorageService } from '../../core/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private apiService: ApiService, private storageService: StorageService) {
  }

  login({ username, password }: Credentials): Observable<User> {
    return new Observable<User>(observer => {
      this.apiService.post('auth/signin', { username, password }).subscribe((response: any) => {
          if (response && response.token && response.user) {
            this.storageService.set('auth-token', response.token);
            this.storageService.set('user', JSON.stringify(response.user));
            observer.next(response.user);
            observer.complete();
          }
        },
        error => {
          observer.error(error);
        });
    });
  }

  logout() {
    return this.apiService.post('auth/signout', null);
  }

  getToken(): string {
    return this.storageService.get('auth-token');
  }

  getLoggedInUser(): User {
    const user = this.storageService.get('user');
    return user ? JSON.parse(user) : null;
  }

  isLoggedIn() {
    return !!this.storageService.get('user');
  }
}
