import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.auth.getToken();
    if (token) {
      // Logged in. Add Bearer token.
      return next.handle(
        request.clone({
          headers: request.headers.append('Authorization', 'Bearer ' + token)
        })
      );
    }
    // Not logged in. Continue without modification.
    return next.handle(request);
  }
}
