import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap
} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromAuth from './auth.reducer';
import { AuthApiActions } from '../actions';

export interface AuthState {
  user: fromAuth.State;
}

export interface State extends fromRoot.State {
  auth: AuthState;
}

export const reducers: ActionReducerMap<AuthState, AuthApiActions.AuthApiActionsUnion> = {
  user: fromAuth.reducer
};

export const selectAuthState = createFeatureSelector<State, AuthState>('auth');
export const selectAuthStatusState = createSelector(selectAuthState, (state: AuthState) => state.user);

export const getLoginPageError = createSelector(selectAuthStatusState, fromAuth.getError);
export const getLoginPagePending = createSelector(selectAuthStatusState, fromAuth.getPending);
export const getUser = createSelector(selectAuthStatusState, fromAuth.getUser);
export const getLoggedIn = createSelector(getUser, user => !!user);
