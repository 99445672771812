import { Component, OnInit } from '@angular/core';
import { ActionsSubject, select, Store } from '@ngrx/store';
import * as fromAuth from '../../../reducers';
import { Credentials } from '../../../models/user';
import { AuthApiActions, LoginPageActions } from '../../../actions';
import { MatDialogRef } from '@angular/material';
import { BaseOnDestroy } from '../../../../core';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-login-confirmation-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent extends BaseOnDestroy implements OnInit {

  pending$ = this.store.pipe(select(fromAuth.getLoginPagePending));
  error$ = this.store.pipe(select(fromAuth.getLoginPageError));

  constructor(private store: Store<fromAuth.State>,
              public dialogRef: MatDialogRef<LoginDialogComponent>,
              private actionsSubject: ActionsSubject) {
    super();
  }

  ngOnInit() {
    this.actionsSubject.pipe(
      filter(action =>
        action.type === AuthApiActions.setLoggedInUser.type),
      takeUntil(this.isDestroyed$))
      .subscribe(() => this.dialogRef.close());
  }

  onSubmit(credentials: Credentials) {
    this.store.dispatch(LoginPageActions.loginFromDialog({ credentials }));
  }
}
