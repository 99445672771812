import { map } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
export class CommonService {
    constructor(apiService) {
        this.apiService = apiService;
    }
    getRoles() {
        return this.apiService.get('roles').pipe(map(roles => {
            roles.pop();
            return roles;
        }));
    }
    /**
     * Get the name of the selected item in a dropdown
     *
     * @author Sukhdeep Singh
     * @param arrDropdown the dropdown array to be searched
     * @param id the selected id in this dropdown
     * @param key the index of array to
     * @return name of the selected item
     */
    getName(arrDropdown, id, key = 'name') {
        let name = '';
        if (arrDropdown && id) {
            for (let i = 0, len = arrDropdown.length; i < len; i++) {
                if (arrDropdown[i].id === id) {
                    name = arrDropdown[i][key];
                    break;
                }
            }
        }
        return name;
    }
    saveResponseBlobAsFile(response, fileName) {
        const fileData = response.body;
        const contentDisposition = response.headers.get('Content-Disposition');
        if (contentDisposition) {
            const fileNameHeader = contentDisposition.split(';')[1];
            if (fileNameHeader) {
                fileName = fileNameHeader.split('=')[1] || fileName;
                fileName = fileName.replace(/"/g, '');
                fileName.trim();
            }
        }
        const fileBlob = new Blob([fileData], { type: fileData.type });
        saveAs(fileBlob, fileName);
    }
}
CommonService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommonService_Factory() { return new CommonService(i0.ɵɵinject(i1.ApiService)); }, token: CommonService, providedIn: "root" });
