export class AppConstants {
  static readonly MOBILE_UI_MAX_WIDTH = 1200;
  static readonly TABLET_PORTRAIT_MAX_WIDTH = 992;
  static readonly DATE_FORMATS = Object.freeze({
    parse: {
      dateInput: 'YYYY-MM-DD'
    },
    display: {
      dateInput: 'MMM D, YYYY',
      monthYearLabel: 'MM YYYY',
      dateA11yLabel: 'DD/MM/YYYY',
      monthYearA11yLabel: 'MM YYYY'
    }
  });

  static readonly nameValidationRegex = '^[a-zA-Z ,.\'-]*$';

  static readonly passwordValidationRegex = '^(?=^.{6,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]).*$';

  static readonly phoneValidationRegex = '\\+?\\(?([0-9]{3})\\)?[-.]?\\(?([0-9]{3})\\)?[-.]?\\(?([0-9]{4})\\)?';
}
