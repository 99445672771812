/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./logout-confirmation-dialog.component";
var styles_LogoutConfirmationDialogComponent = [];
var RenderType_LogoutConfirmationDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LogoutConfirmationDialogComponent, data: {} });
export { RenderType_LogoutConfirmationDialogComponent as RenderType_LogoutConfirmationDialogComponent };
export function View_LogoutConfirmationDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 21, "section", [["class", "dialog-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(2, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(-1, null, ["Logout "])), (_l()(), i0.ɵeld(4, 0, null, null, 4, "button", [["class", "close-btn"], ["mat-icon-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).dialogRef.close(i0.ɵnov(_v, 6).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(5, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), i0.ɵdid(6, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { type: [0, "type"], dialogResult: [1, "dialogResult"] }, null), (_l()(), i0.ɵeld(7, 0, null, 0, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["close"])), (_l()(), i0.ɵeld(9, 0, null, null, 2, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i0.ɵdid(10, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵted(-1, null, ["Are you sure you want to logout?"])), (_l()(), i0.ɵeld(12, 0, null, null, 9, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i0.ɵdid(13, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(14, 0, null, null, 3, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 16).dialogRef.close(i0.ɵnov(_v, 16).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(15, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), i0.ɵdid(16, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i0.ɵted(-1, 0, ["Cancel"])), (_l()(), i0.ɵeld(18, 0, null, null, 3, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 20).dialogRef.close(i0.ɵnov(_v, 20).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(19, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), i0.ɵdid(20, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i0.ɵted(-1, 0, ["OK"]))], function (_ck, _v) { _ck(_v, 2, 0); var currVal_5 = "button"; var currVal_6 = false; _ck(_v, 6, 0, currVal_5, currVal_6); var currVal_11 = false; _ck(_v, 16, 0, currVal_11); var currVal_16 = true; _ck(_v, 20, 0, currVal_16); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).id; _ck(_v, 1, 0, currVal_0); var currVal_1 = (i0.ɵnov(_v, 5).disabled || null); var currVal_2 = (i0.ɵnov(_v, 5)._animationMode === "NoopAnimations"); var currVal_3 = (i0.ɵnov(_v, 6).ariaLabel || null); var currVal_4 = i0.ɵnov(_v, 6).type; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_7 = (i0.ɵnov(_v, 15).disabled || null); var currVal_8 = (i0.ɵnov(_v, 15)._animationMode === "NoopAnimations"); var currVal_9 = (i0.ɵnov(_v, 16).ariaLabel || null); var currVal_10 = i0.ɵnov(_v, 16).type; _ck(_v, 14, 0, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_12 = (i0.ɵnov(_v, 19).disabled || null); var currVal_13 = (i0.ɵnov(_v, 19)._animationMode === "NoopAnimations"); var currVal_14 = (i0.ɵnov(_v, 20).ariaLabel || null); var currVal_15 = i0.ɵnov(_v, 20).type; _ck(_v, 18, 0, currVal_12, currVal_13, currVal_14, currVal_15); }); }
export function View_LogoutConfirmationDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_LogoutConfirmationDialogComponent_0, RenderType_LogoutConfirmationDialogComponent)), i0.ɵdid(1, 49152, null, 0, i6.LogoutConfirmationDialogComponent, [], null, null)], null, null); }
var LogoutConfirmationDialogComponentNgFactory = i0.ɵccf("ng-component", i6.LogoutConfirmationDialogComponent, View_LogoutConfirmationDialogComponent_Host_0, {}, {}, []);
export { LogoutConfirmationDialogComponentNgFactory as LogoutConfirmationDialogComponentNgFactory };
