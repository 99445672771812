import * as tslib_1 from "tslib";
import { Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { AuthActions, AuthApiActions, LoginPageActions } from '../actions';
import { LogoutConfirmationDialogComponent } from '../components/logout/logout-confirmation-dialog/logout-confirmation-dialog.component';
import { CoreActions } from '../../core/actions';
import { AppConstants } from '../../core/constants';
import { LoginDialogComponent } from '../components/login/login-dialog/login-dialog.component';
export class AuthEffects {
    constructor(actions$, authService, router, dialog, storageService, authGuard) {
        this.actions$ = actions$;
        this.authService = authService;
        this.router = router;
        this.dialog = dialog;
        this.storageService = storageService;
        this.authGuard = authGuard;
        this.login$ = this.actions$.pipe(ofType(LoginPageActions.login.type, LoginPageActions.loginFromDialog), exhaustMap(action => this.authService.login(action.credentials).pipe(map(user => {
            return action.type === LoginPageActions.loginFromDialog.type ?
                AuthApiActions.setLoggedInUser({ user }) :
                AuthApiActions.loginSuccess({ user });
        }), catchError(error => of(AuthApiActions.loginFailure({ error }))))));
        this.logout$ = this.actions$.pipe(ofType(AuthActions.logout.type), exhaustMap(() => this.authService.logout().pipe(map(() => {
            this.storageService.removeAll();
            return true;
        }))));
        this.loginSuccess$ = this.actions$.pipe(ofType(AuthApiActions.loginSuccess.type), tap(() => {
            const redirectUrl = this.authGuard.redirectUrl || '/';
            this.router.navigateByUrl(redirectUrl);
        }), map(() => window.innerWidth > AppConstants.MOBILE_UI_MAX_WIDTH ? CoreActions.openSidenav() : CoreActions.closeSidenav()));
        this.loginRedirect$ = this.actions$.pipe(ofType(AuthApiActions.loginRedirect.type, AuthActions.logout.type), tap(() => {
            this.router.navigate(['/login']);
        }), map(() => CoreActions.closeSidenav()));
        this.logoutConfirmation$ = this.actions$.pipe(ofType(AuthActions.logoutConfirmation.type), exhaustMap(() => {
            const dialogRef = this.dialog.open(LogoutConfirmationDialogComponent, { width: '460px' });
            return dialogRef.afterClosed();
        }), map(result => {
            if (result) {
                return AuthActions.logout();
            }
            return AuthActions.logoutConfirmationDismiss();
        }));
        this.sessionExpired$ = this.actions$.pipe(ofType(AuthApiActions.sessionExpired.type), exhaustMap(() => {
            const dialogRef = this.dialog.open(LoginDialogComponent);
            return dialogRef.afterClosed();
        }));
    }
}
tslib_1.__decorate([
    Effect()
], AuthEffects.prototype, "login$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false })
], AuthEffects.prototype, "logout$", void 0);
tslib_1.__decorate([
    Effect()
], AuthEffects.prototype, "loginSuccess$", void 0);
tslib_1.__decorate([
    Effect()
], AuthEffects.prototype, "loginRedirect$", void 0);
tslib_1.__decorate([
    Effect()
], AuthEffects.prototype, "logoutConfirmation$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false })
], AuthEffects.prototype, "sessionExpired$", void 0);
