/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nav-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/list";
import * as i4 from "@angular/material/icon";
import * as i5 from "@angular/material/core";
import * as i6 from "../../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i7 from "@angular/router";
import * as i8 from "@angular/common";
import * as i9 from "./nav-item.component";
var styles_NavItemComponent = [i0.styles];
var RenderType_NavItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavItemComponent, data: {} });
export { RenderType_NavItemComponent as RenderType_NavItemComponent };
function View_NavItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-icon", [["class", "mat-list-icon mat-icon notranslate"], ["mat-list-icon", ""], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 16384, [[5, 4]], 0, i3.MatListIconCssMatStyler, [], null, null), i1.ɵdid(2, 9158656, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null], [2, i4.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(3, 0, ["", ""]))], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.icon; _ck(_v, 3, 0, currVal_2); }); }
function View_NavItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "secondary mat-line"], ["mat-line", ""]], null, null, null, null, null)), i1.ɵdid(1, 16384, [[3, 4]], 0, i5.MatLine, [], null, null), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hint; _ck(_v, 2, 0, currVal_0); }); }
export function View_NavItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "a", [["class", "mat-list-item"], ["mat-list-item", ""]], [[1, "target", 0], [8, "href", 4], [2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.navigate.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_MatListItem_0, i6.RenderType_MatListItem)), i1.ɵdid(1, 671744, [[2, 4]], 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(2, 1720320, null, 2, i7.RouterLinkActive, [i7.Router, i1.ElementRef, i1.Renderer2, [2, i7.RouterLink], [2, i7.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i1.ɵdid(5, 1228800, null, 3, i3.MatListItem, [i1.ElementRef, i1.ChangeDetectorRef, [2, i3.MatNavList], [2, i3.MatList]], null, null), i1.ɵqud(603979776, 3, { _lines: 1 }), i1.ɵqud(603979776, 4, { _avatar: 0 }), i1.ɵqud(603979776, 5, { _icon: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_NavItemComponent_1)), i1.ɵdid(10, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, 1, 2, "span", [["class", "mat-line"], ["mat-line", ""]], null, null, null, null, null)), i1.ɵdid(12, 16384, [[3, 4]], 0, i5.MatLine, [], null, null), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_NavItemComponent_2)), i1.ɵdid(15, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.link; _ck(_v, 1, 0, currVal_4); var currVal_5 = _co.linkActive; _ck(_v, 2, 0, currVal_5); var currVal_6 = _co.icon; _ck(_v, 10, 0, currVal_6); var currVal_7 = _co.hint; _ck(_v, 15, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; var currVal_2 = (i1.ɵnov(_v, 5)._avatar || i1.ɵnov(_v, 5)._icon); var currVal_3 = (i1.ɵnov(_v, 5)._avatar || i1.ɵnov(_v, 5)._icon); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_NavItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-nav-item", [], null, null, null, View_NavItemComponent_0, RenderType_NavItemComponent)), i1.ɵdid(1, 49152, null, 0, i9.NavItemComponent, [], null, null)], null, null); }
var NavItemComponentNgFactory = i1.ɵccf("app-nav-item", i9.NavItemComponent, View_NavItemComponent_Host_0, { icon: "icon", hint: "hint", link: "link", linkActive: "linkActive" }, { navigate: "navigate" }, ["*"]);
export { NavItemComponentNgFactory as NavItemComponentNgFactory };
