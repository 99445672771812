import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {
  }

  set(itemName: string, value: string) {
    localStorage.setItem(itemName, value);
  }

  get(itemName: string): string {
    return localStorage.getItem(itemName);
  }

  remove(itemName: string) {
    localStorage.removeItem(itemName);
  }

  removeAll() {
    localStorage.clear();
  }
}
