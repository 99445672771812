import { HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { isEmpty } from 'lodash';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthApiActions } from '../../auth/actions';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
export class ApiService {
    constructor(http, store) {
        this.http = http;
        this.store = store;
        this.apiEndPoint = environment.apiUrl;
        this.headers = new HttpHeaders({
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache'
        });
    }
    get(path, params) {
        return this.http.get(this.apiEndPoint + '/' + path, {
            params: this.generateHttpParamsFromObject(params),
            headers: this.headers
        }).pipe(catchError(error => this.handleError(error)));
    }
    getHTML(path, params) {
        return this.http.get(this.apiEndPoint + '/' + path, {
            params: this.generateHttpParamsFromObject(params),
            headers: this.headers,
            responseType: 'text'
        }).pipe(catchError(error => this.handleError(error)));
    }
    getBlob(path, params) {
        return this.http.get(this.apiEndPoint + '/' + path, {
            params: this.generateHttpParamsFromObject(params),
            observe: 'response',
            headers: this.headers,
            responseType: 'blob'
        }).pipe(catchError(error => this.handleError(error)));
    }
    post(path, body) {
        return this.http.post(this.apiEndPoint + '/' + path, body, { headers: this.headers }).pipe(catchError(error => this.handleError(error)));
    }
    patch(path, body) {
        return this.http.patch(this.apiEndPoint + '/' + path, body, { headers: this.headers }).pipe(catchError(error => this.handleError(error)));
    }
    delete(path) {
        return this.http.delete(this.apiEndPoint + '/' + path, { headers: this.headers }).pipe(catchError(error => this.handleError(error)));
    }
    put(path, body) {
        return this.http.put(this.apiEndPoint + '/' + path, body, { headers: this.headers }).pipe(catchError(error => this.handleError(error)));
    }
    generateHttpParamsFromObject(params) {
        let httpParams = new HttpParams();
        if (params) {
            Object.keys(params).forEach(param => {
                if (params[param]) {
                    httpParams = httpParams.set(param, params[param]);
                }
            });
        }
        return httpParams;
    }
    handleError(httpErrorResponse) {
        console.error(httpErrorResponse);
        let message = '';
        if (httpErrorResponse.status === 422 && Array.isArray(httpErrorResponse.error.errors)) {
            return throwError(httpErrorResponse.error.errors);
        }
        if (httpErrorResponse.status === 403 && httpErrorResponse.error && !isEmpty(httpErrorResponse.error.error)) {
            return throwError(httpErrorResponse.error.error.message);
        }
        if (httpErrorResponse.status === 401 && httpErrorResponse.error === 'Unauthorized' && httpErrorResponse.url.indexOf('signout') === -1) {
            this.store.dispatch(AuthApiActions.sessionExpired());
            message = 'Your session has expired. Please login again';
        }
        else {
            switch (httpErrorResponse.status) {
                case 400:
                    message = httpErrorResponse.error && httpErrorResponse.error.hasOwnProperty('message')
                        ? httpErrorResponse.error.message : 'An error occurred, please try again';
                    break;
                case 401:
                    message = 'You do not have enough permission to do this';
                    break;
                case 403:
                    message = 'Unable to process the request';
                    break;
                case 404:
                    message = 'The requested record does not exist';
                    break;
                case 413:
                    message = 'Data upload size is too large';
                    break;
                case 422:
                    message = 'Unable to recognize the data format';
                    break;
                case 503:
                case 504:
                    message = 'Unable to reach server, it took too long. Please try again';
                    break;
                default:
                    message = 'Something went wrong. Please try again';
                    break;
            }
        }
        // return an observable with a user-facing error message
        return throwError(message);
    }
}
ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Store)); }, token: ApiService, providedIn: "root" });
