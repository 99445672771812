/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./print-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "./print-dialog.component";
import * as i4 from "@angular/material/snack-bar";
var styles_PrintDialogComponent = [i0.styles];
var RenderType_PrintDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrintDialogComponent, data: {} });
export { RenderType_PrintDialogComponent as RenderType_PrintDialogComponent };
export function View_PrintDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { printFrame: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["printFrame", 1]], null, 0, "iframe", [], null, null, null, null, null))], null, null); }
export function View_PrintDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-print-dialog", [], null, null, null, View_PrintDialogComponent_0, RenderType_PrintDialogComponent)), i1.ɵdid(1, 4243456, null, 0, i3.PrintDialogComponent, [i2.MAT_DIALOG_DATA, i2.MatDialogRef, i4.MatSnackBar], null, null)], null, null); }
var PrintDialogComponentNgFactory = i1.ɵccf("app-print-dialog", i3.PrintDialogComponent, View_PrintDialogComponent_Host_0, {}, {}, []);
export { PrintDialogComponentNgFactory as PrintDialogComponentNgFactory };
