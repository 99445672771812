import * as i0 from "@angular/core";
export class StorageService {
    constructor() {
    }
    set(itemName, value) {
        localStorage.setItem(itemName, value);
    }
    get(itemName) {
        return localStorage.getItem(itemName);
    }
    remove(itemName) {
        localStorage.removeItem(itemName);
    }
    removeAll() {
        localStorage.clear();
    }
}
StorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageService_Factory() { return new StorageService(); }, token: StorageService, providedIn: "root" });
