/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../login-form/login-form.component.ngfactory";
import * as i3 from "../login-form/login-form.component";
import * as i4 from "@angular/common";
import * as i5 from "./login-page.component";
import * as i6 from "@ngrx/store";
var styles_LoginPageComponent = [i0.styles];
var RenderType_LoginPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginPageComponent, data: {} });
export { RenderType_LoginPageComponent as RenderType_LoginPageComponent };
export function View_LoginPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-login-form", [], null, [[null, "submitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitted" === en)) {
        var pd_0 = (_co.onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LoginFormComponent_0, i2.RenderType_LoginFormComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoginFormComponent, [], { pending: [0, "pending"], errorMessage: [1, "errorMessage"] }, { submitted: "submitted" }), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.pending$)); var currVal_1 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 3).transform(_co.error$)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_LoginPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login-page", [], null, null, null, View_LoginPageComponent_0, RenderType_LoginPageComponent)), i1.ɵdid(1, 114688, null, 0, i5.LoginPageComponent, [i6.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginPageComponentNgFactory = i1.ɵccf("app-login-page", i5.LoginPageComponent, View_LoginPageComponent_Host_0, {}, {}, []);
export { LoginPageComponentNgFactory as LoginPageComponentNgFactory };
