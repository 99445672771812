import { Action } from '@ngrx/store';
import { CoreActions } from '../actions';

export interface State {
  showSidenav: boolean;
}

const initialState: State = {
  showSidenav: false
};

export function reducer(state: State = initialState, action: Action): State {
  const specificAction = action as CoreActions.CoreActionsUnion;

  switch (specificAction.type) {
    case CoreActions.closeSidenav.type:
      return {
        ...state,
        showSidenav: false
      };

    case CoreActions.openSidenav.type:
      return {
        ...state,
        showSidenav: true
      };

    case CoreActions.toggleSidenav.type:
      return {
        ...state,
        showSidenav: !state.showSidenav
      };
    default:
      return state;
  }
}

export const getShowSidenav = (state: State) => state.showSidenav;
