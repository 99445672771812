import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { AuthActions, AuthApiActions, LoginPageActions } from '../actions';
import { LogoutConfirmationDialogComponent } from '../components/logout/logout-confirmation-dialog/logout-confirmation-dialog.component';
import { AuthService } from '../services/auth.service';
import { CoreActions } from '../../core/actions';
import { AppConstants } from '../../core/constants';
import { StorageService } from '../../core/services/storage.service';
import { AuthGuard } from '../services/auth-guard.service';
import { LoginDialogComponent } from '../components/login/login-dialog/login-dialog.component';

@Injectable()
export class AuthEffects {
  @Effect()
  login$ = this.actions$.pipe(
    ofType(LoginPageActions.login.type, LoginPageActions.loginFromDialog),
    exhaustMap(action =>
      this.authService.login(action.credentials).pipe(
        map(user => {
          return action.type === LoginPageActions.loginFromDialog.type ?
            AuthApiActions.setLoggedInUser({ user }) :
            AuthApiActions.loginSuccess({ user });
        }),
        catchError(error => of(AuthApiActions.loginFailure({ error })))
      )
    )
  );

  @Effect({ dispatch: false })
  logout$ = this.actions$.pipe(
    ofType(AuthActions.logout.type),
    exhaustMap(() => this.authService.logout().pipe(
      map(() => {
        this.storageService.removeAll();
        return true;
      })))
  );

  @Effect()
  loginSuccess$ = this.actions$.pipe(
    ofType(AuthApiActions.loginSuccess.type),
    tap(() => {
      const redirectUrl = this.authGuard.redirectUrl || '/';
      this.router.navigateByUrl(redirectUrl);
    }),
    map(() => window.innerWidth > AppConstants.MOBILE_UI_MAX_WIDTH ? CoreActions.openSidenav() : CoreActions.closeSidenav())
  );

  @Effect()
  loginRedirect$ = this.actions$.pipe(
    ofType(AuthApiActions.loginRedirect.type, AuthActions.logout.type),
    tap(() => {
      this.router.navigate(['/login']);
    }),
    map(() => CoreActions.closeSidenav())
  );

  @Effect()
  logoutConfirmation$ = this.actions$.pipe(
    ofType(AuthActions.logoutConfirmation.type),
    exhaustMap(() => {
      const dialogRef = this.dialog.open<LogoutConfirmationDialogComponent,
        undefined,
        boolean>(LogoutConfirmationDialogComponent, { width: '460px' });

      return dialogRef.afterClosed();
    }),
    map(result => {
        if (result) {
          return AuthActions.logout();
        }
        return AuthActions.logoutConfirmationDismiss();
      }
    )
  );

  @Effect({ dispatch: false })
  sessionExpired$ = this.actions$.pipe(
    ofType(AuthApiActions.sessionExpired.type),
    exhaustMap(() => {
      const dialogRef = this.dialog.open<LoginDialogComponent,
        undefined,
        boolean>(LoginDialogComponent);
      return dialogRef.afterClosed();
    })
  );


  constructor(
    private actions$: Actions<LoginPageActions.LoginPageActionsUnion>,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private storageService: StorageService,
    private authGuard: AuthGuard
  ) {
  }
}
