<section class="dialog-container">
  <h2 mat-dialog-title>Unsaved Changes</h2>
  <mat-dialog-content>
    <p>You have unsaved changes on this page.</p>
    <p>Clicking on <strong>OK</strong> will discard changes and close this page.</p>
    <p>Clicking on <strong>Cancel</strong> will close this dialog so that you can save your changes by clicking on the
      save button.</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-button [mat-dialog-close]="true">OK</button>
  </mat-dialog-actions>
</section>
