import { Component } from '@angular/core';
import * as fromRoot from '../../../reducers';
import { Store } from '@ngrx/store';
import { CoreActions } from '../../actions';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

  constructor(private store: Store<fromRoot.State>) {
  }

  toggleSidenav() {
    this.store.dispatch(CoreActions.toggleSidenav());
  }
}
