import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../core/services/api.service";
import * as i2 from "../../core/services/storage.service";
export class AuthService {
    constructor(apiService, storageService) {
        this.apiService = apiService;
        this.storageService = storageService;
    }
    login({ username, password }) {
        return new Observable(observer => {
            this.apiService.post('auth/signin', { username, password }).subscribe((response) => {
                if (response && response.token && response.user) {
                    this.storageService.set('auth-token', response.token);
                    this.storageService.set('user', JSON.stringify(response.user));
                    observer.next(response.user);
                    observer.complete();
                }
            }, error => {
                observer.error(error);
            });
        });
    }
    logout() {
        return this.apiService.post('auth/signout', null);
    }
    getToken() {
        return this.storageService.get('auth-token');
    }
    getLoggedInUser() {
        const user = this.storageService.get('user');
        return user ? JSON.parse(user) : null;
    }
    isLoggedIn() {
        return !!this.storageService.get('user');
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.StorageService)); }, token: AuthService, providedIn: "root" });
