import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AuthActions, AuthApiActions } from './auth/actions';
import * as fromAuth from './auth/reducers';
import * as fromRoot from './reducers';
import { CoreActions } from './core/actions';
import { CommonService } from './core/services/common.service';
import { AppConstants } from './core/constants';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { User } from './auth/models/user';
import { AuthService } from './auth/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showSidenav$: Observable<boolean>;
  loggedIn$: Observable<boolean>;
  currentUser$: Observable<User>;
  isMobile = false;
  userActionsMenuOpen = false;

  constructor(private store: Store<fromRoot.State & fromAuth.State>,
              private commonService: CommonService,
              private authService: AuthService,
              public breakpointObserver: BreakpointObserver) {
    /**
     * Selectors can be applied with the `select` operator which passes the state
     * tree to the provided selector
     */
    this.showSidenav$ = this.store.pipe(select(fromRoot.getShowSidenav));
    this.loggedIn$ = this.store.pipe(select(fromAuth.getLoggedIn));
    this.currentUser$ = this.store.pipe(select(fromAuth.getUser));
  }

  ngOnInit(): void {
    const loggedInUser = this.authService.getLoggedInUser();
    if (loggedInUser) {
      this.store.dispatch(AuthApiActions.setLoggedInUser({ user: loggedInUser }));
    }

    this.breakpointObserver
      .observe([`(max-width: ${AppConstants.TABLET_PORTRAIT_MAX_WIDTH}px)`])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
          this.closeSidenav();
        } else {
          this.isMobile = false;
          if (this.authService.isLoggedIn()) {
            this.openSidenav();
          }
        }
      });
  }

  closeSidenav() {
    this.store.dispatch(CoreActions.closeSidenav());
  }

  openSidenav() {
    this.store.dispatch(CoreActions.openSidenav());
  }

  logout() {
    this.store.dispatch(AuthActions.logoutConfirmation());
  }

  onMenuItemNavigate() {
    if (window.innerWidth <= AppConstants.TABLET_PORTRAIT_MAX_WIDTH) {
      this.closeSidenav();
    }
  }
}
