import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { AuthApiActions } from '../../auth/actions';
import * as fromAuth from '../../auth/reducers';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  public redirectUrl: string;

  constructor(private store: Store<fromAuth.State>, private router: Router, private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // Store the attempted URL for redirecting
    this.redirectUrl = state.url;
    if (this.authService.isLoggedIn()) {
      return of(true);
    } else {
      this.store.dispatch(AuthApiActions.loginRedirect());
      return of(false);
    }
  }
}
