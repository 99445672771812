/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../login-form/login-form.component.ngfactory";
import * as i3 from "../login-form/login-form.component";
import * as i4 from "@angular/common";
import * as i5 from "./login-dialog.component";
import * as i6 from "@ngrx/store";
import * as i7 from "@angular/material/dialog";
var styles_LoginDialogComponent = [i0.styles];
var RenderType_LoginDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginDialogComponent, data: {} });
export { RenderType_LoginDialogComponent as RenderType_LoginDialogComponent };
export function View_LoginDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "section", [["class", "mt-4 pr-4 pl-4 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your session has expired. Please re-enter your credentials to continue working."])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["After logging in, please retry what you wanted to do."])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "app-login-form", [], null, [[null, "submitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitted" === en)) {
        var pd_0 = (_co.onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LoginFormComponent_0, i2.RenderType_LoginFormComponent)), i1.ɵdid(6, 114688, null, 0, i3.LoginFormComponent, [], { pending: [0, "pending"], errorMessage: [1, "errorMessage"] }, { submitted: "submitted" }), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.pending$)); var currVal_1 = i1.ɵunv(_v, 6, 1, i1.ɵnov(_v, 8).transform(_co.error$)); _ck(_v, 6, 0, currVal_0, currVal_1); }, null); }
export function View_LoginDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login-confirmation-dialog", [], null, null, null, View_LoginDialogComponent_0, RenderType_LoginDialogComponent)), i1.ɵdid(1, 245760, null, 0, i5.LoginDialogComponent, [i6.Store, i7.MatDialogRef, i6.ActionsSubject], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginDialogComponentNgFactory = i1.ɵccf("app-login-confirmation-dialog", i5.LoginDialogComponent, View_LoginDialogComponent_Host_0, {}, {}, []);
export { LoginDialogComponentNgFactory as LoginDialogComponentNgFactory };
