import { Component } from '@angular/core';

@Component({
  template: `
      <section class="dialog-container">
          <h2 mat-dialog-title>Logout
              <button type="button" [mat-dialog-close]="false" mat-icon-button class="close-btn">
                  <i class="material-icons">close</i>
              </button>
          </h2>
          <mat-dialog-content>Are you sure you want to logout?</mat-dialog-content>
          <mat-dialog-actions align="end">
              <button mat-button [mat-dialog-close]="false">Cancel</button>
              <button mat-button [mat-dialog-close]="true">OK</button>
          </mat-dialog-actions>
      </section>
  `
})
export class LogoutConfirmationDialogComponent {
}
