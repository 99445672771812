import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { isEmpty } from 'lodash';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import * as fromAuth from '../../auth/reducers';
import { AuthApiActions } from '../../auth/actions';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  private apiEndPoint = environment.apiUrl;
  private headers = new HttpHeaders({
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache'
  });

  constructor(private http: HttpClient,
              private store: Store<fromAuth.State>) {
  }

  public get<T>(path: string, params?: any): Observable<T> {
    return this.http.get<T>(this.apiEndPoint + '/' + path, {
      params: this.generateHttpParamsFromObject(params),
      headers: this.headers
    }).pipe(
      catchError(error => this.handleError(error))
    );
  }

  public getHTML(path: string, params?: any): Observable<string> {
    return this.http.get(this.apiEndPoint + '/' + path, {
      params: this.generateHttpParamsFromObject(params),
      headers: this.headers,
      responseType: 'text'
    }).pipe(
      catchError(error => this.handleError(error))
    );
  }

  public getBlob<Blob>(path: string, params?: any): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(this.apiEndPoint + '/' + path, {
      params: this.generateHttpParamsFromObject(params),
      observe: 'response',
      headers: this.headers,
      responseType: 'blob' as 'json'
    }).pipe(
      catchError(error => this.handleError(error))
    );
  }

  public post<T>(path: string, body): Observable<T> {
    return this.http.post<T>(this.apiEndPoint + '/' + path, body, { headers: this.headers }).pipe(
      catchError(error => this.handleError(error))
    );
  }

  public patch<T>(path: string, body): Observable<T> {
    return this.http.patch<T>(this.apiEndPoint + '/' + path, body, { headers: this.headers }).pipe(
      catchError(error => this.handleError(error))
    );
  }

  public delete<T>(path: string): Observable<T> {
    return this.http.delete<T>(this.apiEndPoint + '/' + path, { headers: this.headers }).pipe(
      catchError(error => this.handleError(error))
    );
  }

  public put<T>(path: string, body): Observable<T> {
    return this.http.put<T>(this.apiEndPoint + '/' + path, body, { headers: this.headers }).pipe(
      catchError(error => this.handleError(error))
    );
  }

  private generateHttpParamsFromObject(params?: any) {
    let httpParams: HttpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach(param => {
        if (params[param]) {
          httpParams = httpParams.set(param, params[param]);
        }
      });
    }
    return httpParams;
  }

  private handleError(httpErrorResponse: HttpErrorResponse) {
    console.error(httpErrorResponse);
    let message = '';

    if (httpErrorResponse.status === 422 && Array.isArray(httpErrorResponse.error.errors)) {
      return throwError(httpErrorResponse.error.errors);
    }

    if (httpErrorResponse.status === 403 && httpErrorResponse.error && !isEmpty(httpErrorResponse.error.error)) {
      return throwError(httpErrorResponse.error.error.message);
    }

    if (httpErrorResponse.status === 401 && httpErrorResponse.error === 'Unauthorized' && httpErrorResponse.url.indexOf('signout') === -1) {
      this.store.dispatch(AuthApiActions.sessionExpired());
      message = 'Your session has expired. Please login again';
    } else {
      switch (httpErrorResponse.status) {
        case 400:
          message = httpErrorResponse.error && httpErrorResponse.error.hasOwnProperty('message')
            ? httpErrorResponse.error.message : 'An error occurred, please try again';
          break;
        case 401:
          message = 'You do not have enough permission to do this';
          break;
        case 403:
          message = 'Unable to process the request';
          break;
        case 404:
          message = 'The requested record does not exist';
          break;
        case 413:
          message = 'Data upload size is too large';
          break;
        case 422:
          message = 'Unable to recognize the data format';
          break;
        case 503:
        case 504:
          message = 'Unable to reach server, it took too long. Please try again';
          break;
        default:
          message = 'Something went wrong. Please try again';
          break;
      }
    }
    // return an observable with a user-facing error message
    return throwError(message);
  }

}
