import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Role } from '../../admin/models/role';
import { map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private apiService: ApiService) {
  }

  getRoles() {
    return this.apiService.get<Array<Role>>('roles').pipe(map(roles => {
      roles.pop();
      return roles;
    }));
  }

  /**
   * Get the name of the selected item in a dropdown
   *
   * @author Sukhdeep Singh
   * @param arrDropdown the dropdown array to be searched
   * @param id the selected id in this dropdown
   * @param key the index of array to
   * @return name of the selected item
   */
  getName(arrDropdown: Array<any>, id: string | number, key: string = 'name'): string {
    let name = '';
    if (arrDropdown && id) {
      for (let i = 0, len: number = arrDropdown.length; i < len; i++) {
        if (arrDropdown[i].id === id) {
          name = arrDropdown[i][key];
          break;
        }
      }
    }
    return name;
  }

  saveResponseBlobAsFile(response: HttpResponse<Blob>, fileName: string) {
    const fileData = response.body;
    const contentDisposition = response.headers.get('Content-Disposition');
    if (contentDisposition) {
      const fileNameHeader = contentDisposition.split(';')[1];
      if (fileNameHeader) {
        fileName = fileNameHeader.split('=')[1] || fileName;
        fileName = fileName.replace(/"/g, '');
        fileName.trim();
      }
    }
    const fileBlob = new Blob([fileData], { type: fileData.type });
    saveAs(fileBlob, fileName);
  }
}
