<div class="app-container" [class.with-background]="!(loggedIn$ | async)" [class.is-mobile]="isMobile">
  <mat-sidenav-container>
    <mat-sidenav [opened]="showSidenav$ | async" [mode]="isMobile ? 'over' : 'side'"
                 [fixedInViewport]="isMobile"
                 [fixedTopGap]="0"
                 [fixedBottomGap]="0"
                 [disableClose]="true"
                 [autoFocus]="false">
      <mat-nav-list *ngIf="loggedIn$ | async">
        <a class="sidenav-logo" mat-list-item>
          <img src="../assets/images/logo.png" alt="Netex Logo"/>
          <span class="close-menu-mobile" (click)="closeSidenav()">
            <mat-icon aria-hidden="false" aria-label="Close Menu">menu</mat-icon>
          </span>
        </a>
        <mat-divider></mat-divider>
        <app-nav-item (navigate)="onMenuItemNavigate()" link="/home" linkActive="active-menu"
                      icon="dashboard">
          Home
        </app-nav-item>
        <mat-divider></mat-divider>
        <h3 mat-subheader>System Management</h3>
        <app-nav-item (navigate)="onMenuItemNavigate()" link="/admin/access-controls" linkActive="active-menu"
                      icon="security">
          Access Control
        </app-nav-item>
        <app-nav-item (navigate)="onMenuItemNavigate()" link="/admin/users" linkActive="active-menu"
                      icon="people">
          Users
        </app-nav-item>
        <app-nav-item (navigate)="onMenuItemNavigate()" link="/admin/activity-logs" linkActive="active-menu"
                      icon="timeline">
          Activity Log
        </app-nav-item>
        <app-nav-item (navigate)="onMenuItemNavigate()" link="/admin/sessions" linkActive="active-menu"
                      icon="track_changes">
          User Sessions
        </app-nav-item>
        <app-nav-item (navigate)="onMenuItemNavigate()" link="/admin/api-logs" linkActive="active-menu"
                      icon="swap_vert">
          Api Access Log
        </app-nav-item>
        <app-nav-item (navigate)="onMenuItemNavigate()" link="/admin/system-settings" linkActive="active-menu"
                      icon="settings">
          Settings
        </app-nav-item>
        <a *ngIf="currentUser$ | async as currentUser" mat-list-item [matMenuTriggerFor]="userActionsMenu"
           (menuOpened)="userActionsMenuOpen = true;" (menuClosed)="userActionsMenuOpen = false;"
           class="user-info-menu-item">
          <span *ngIf="currentUser.username" mat-list-icon
                class="user-initials">{{currentUser.username.slice(0, 1) | uppercase}}</span>
          <span mat-line>{{currentUser.username}}</span>
          <span mat-line class="user-role">{{currentUser.role}}</span>
          <span class="caret">
            <mat-icon aria-hidden="false" aria-label="More Options">
              <ng-container *ngIf="!userActionsMenuOpen; else arrowUpIcon">keyboard_arrow_down</ng-container>
              <ng-template #arrowUpIcon>keyboard_arrow_up</ng-template>
            </mat-icon>
          </span>
        </a>
        <mat-menu #userActionsMenu="matMenu" xPosition="before" yPosition="above">
          <button mat-menu-item (click)="logout()">
            <mat-icon aria-hidden="false" aria-label="Log Out">power_settings_new</mat-icon>
            <span>Logout</span>
          </button>
        </mat-menu>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
