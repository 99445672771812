import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-print-dialog',
  templateUrl: './print-dialog.component.html',
  styleUrls: ['./print-dialog.component.scss']
})
export class PrintDialogComponent implements AfterViewInit {
  @ViewChild('printFrame', { static: true }) printFrame: ElementRef;
  html: string;

  constructor(@Inject(MAT_DIALOG_DATA) data,
              public dialogRef: MatDialogRef<PrintDialogComponent>,
              private snackBar: MatSnackBar) {
    this.html = data;
  }

  ngAfterViewInit(): void {
    const iframeElement: HTMLIFrameElement = this.printFrame.nativeElement;
    const printDoc = iframeElement.contentDocument;
    const printWindow = iframeElement.contentWindow;
    if (printDoc && printWindow) {
      printDoc.open();
      printDoc.write(this.html);
      printDoc.close();
      printWindow.onload = () => {
        printWindow.print();
      };
      // dialogRef.close() can go inside the above onload block after print method call
      // but the print window behaviour is different in Firefox, as a result, it needs to be a timeout
      // this setup produces most consistent results across Chrome, Firefox, Edge and Opera
      setTimeout(() => {
        this.dialogRef.close();
      }, 1000);
    } else {
      this.snackBar.open('Your web browser does not support printing web pages', 'OK', { duration: 4000 });
    }
  }

}
